import React, { useState, useEffect } from 'react';
import Appearance from 'styles/Appearance.js';
import { VelocityComponent } from 'velocity-react';

const AlertStack = ({ children }) => {

    const [visible, setVisible] = useState(false);
    const [opacity, setOpacity] = useState(0);

    const show = () => {
        setVisible(true);
        setTimeout(() => {
            setOpacity(1);
        }, 100);
    }
    const hide = () => {
        setOpacity(0);
        setTimeout(() => {
            setVisible(false);
        }, 250);
    }

    useEffect(() => {
        if(children.length > 0) {
            show();
        } else if(visible) {
            hide();
        }

    }, [children]);

    return visible ? (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: Appearance.colors.transparent,
            zIndex: 9950
        }}>
            <VelocityComponent
            duration={250}
            animation={{
                opacity: opacity
            }}>
                <div style={{
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    position: 'absolute',
                    backgroundColor: Appearance.colors.dim
                }} />
            </VelocityComponent>
            {children}
        </div>
    ) : null;
}
export default AlertStack;
