import React, { useEffect, useState } from 'react';
import QueryString from 'query-string';

export const CustomGlobalDataSurvey = ({ dealership }) => {

    const [token, setToken] = useState(null);

    const getContent = () => {
        if(token) {
            return (
                <GlobalDataSurvey 
                dealership={dealership}
                shortUrlToken={token}/>
            )
        }
        return null;
    }

    useEffect(() => {
        let { token } = QueryString.parse(window.location.search);
        setToken(token);
    }, []);

    return getContent();
}

export const EmergencyContactOnboardingSurvey = ({ dealership }) => {

    const [surveyID, setSurveyID] = useState(null);

    useEffect(() => {
        let { id } = QueryString.parse(window.location.search);
        setSurveyID(id);
    }, []);

    return (
        <GlobalDataSurvey 
        dealership={dealership}
        id={surveyID} />
    )
}

const GlobalDataSurvey = ({ dealership, id, shortUrlToken }) => {

    const [height, setHeight] = useState(window.innerHeight);

    const onHeightChange = evt => {
        if(evt.data.height) {
            setHeight(evt.data.height + 80);
        }
    }

    const getUrl = () => {
        if(id) {
            return `https://${window.location.hostname}/leads/?id=${id}&dealership_id=${dealership.id}&embeded=1`;
        }
        if(shortUrlToken) {
            return `https://${window.location.hostname}/system/gdl-short-urls/index.php?token=${shortUrlToken}`;
        }
        return '';
    }
    
    useEffect(() => {

        // add event listener for iframe contents height change
        window.addEventListener('message', onHeightChange);
        return () => {
            window.removeEventListener('message', onHeightChange);
        }

    }, []);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%'
            }}>
                <iframe 
                src={getUrl()}
                style={{
                    border: 'none',
                    height: height,
                    width: '100%'
                }} />
            </div>
        </div>
    )
}

export const LeadGenerationSurvey = ({ dealership }) => {
    return (
        <GlobalDataSurvey 
        dealership={dealership}
        id={dealership.lead_generation_survey.id} />
    )
}

export const RecruitingSurvey = ({ dealership }) => {
    return (
        <GlobalDataSurvey 
        dealership={dealership}
        id={dealership.recruiting_survey.id} />
    )
}