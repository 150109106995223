import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Appearance from 'styles/Appearance.js';
import LinearProgress from '@material-ui/core/LinearProgress';

const ProgressBar = withStyles({
    colorPrimary: {
        backgroundColor: Appearance.colors.soft()
    },
    barColorPrimary: {
        backgroundColor: Appearance.colors.primary()
    }
})(LinearProgress);

export default ProgressBar;
