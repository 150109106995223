import React, { useEffect, useRef } from 'react';

import API from 'files/api.js';
import Appearance from 'styles/Appearance.js';
import Utils from 'files/Utils.js';

export const Footer = ({ dealership, utils }) => {

    const getContent = () => {

        if(!dealership) {
            return null;
        }

        // determine if a mobile layout was requested 
        if(Utils.isMobile() === true) {
            return (
                <div style={{
                    alignItems: 'center',
                    backgroundColor: dealership.colors.primary || '#232323',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 24,
                    textAlign: 'center',
                    width: '100%'
                }}>
                    {typeof(dealership.bbb_url) === 'string' && (
                        <img
                        alt={`${dealership.name} BBB Business Review`}
                        className={'text-button'} 
                        onClick={window.open.bind(this, `${dealership.bbb_url}/#sealclick`)}
                        src={'images/bbb-badge.png'} 
                        style={{
                            border: 0,
                            height: 50,
                            marginBottom: 24,
                            objectFit: 'contain',
                            width: 'auto'
                        }} />
                    )}
                    <span style={{
                        color: 'white',
                        fontSize: 18,
                        fontFamily: Appearance.fonts.getFamilyForWeight(700),
                        marginBottom: 2,
                    }}>{`© 2024 ${dealership.name}`}</span>
                    <span style={{
                        color: 'white',
                        fontSize: 12,
                        fontFamily: Appearance.fonts.getFamilyForWeight(500),
                    }}>{`Version ${API.app_version} Build ${API.build}`}</span>
                    <div style={{
                        alignItems: 'center',
                        borderTop: `1px solid white`,
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: 24,
                        marginTop: 24,
                        width: '100%'
                    }}>
                        <span 
                        className={'text-button'}
                        onClick={window.open.bind(this, 'https://homesafealerts.com/dealers')}
                        style={{
                            color: 'white',
                            fontSize: 16,
                            fontFamily: Appearance.fonts.getFamilyForWeight(600),
                            marginBottom: 12,
                            textDecoration: 'underline',
                            width: 250
                        }}>{'Dealer Login'}</span>
                        <span style={{
                            color: 'white',
                            fontSize: 16,
                            fontFamily: Appearance.fonts.getFamilyForWeight(600),
                            marginBottom: 8,
                            whiteSpace: 'nowrap'
                        }}>{`Website Designed by `}
                            <span 
                            className={'text-button'}
                            onClick={window.open.bind(this, 'https://duralabs.io')}
                            style={{
                                textDecoration: 'underline'
                            }}>{`Dura Labs LLC`}</span>
                        </span>
                    </div>
                </div>
            )
        }

        return (
            <div style={{
                backgroundColor: dealership.colors.primary || '#232323',
                display: 'flex',
                flexDirection: 'column',
                padding: '2vw',
                width: '100%'
            }}>
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%'
                }}>
                    <div style={{
                        alignItems: 'flex-start',
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'left',
                        width: 250
                    }}>
                        <span style={{
                            color: 'white',
                            fontSize: 18,
                            fontFamily: Appearance.fonts.getFamilyForWeight(700),
                            marginBottom: 2,
                            whiteSpace: 'nowrap'
                        }}>{`© 2024 ${dealership.name}`}</span>
                        <span style={{
                            color: 'white',
                            fontSize: 12,
                            fontFamily: Appearance.fonts.getFamilyForWeight(500),
                            whiteSpace: 'nowrap'
                        }}>{`Version ${API.app_version} Build ${API.build}`}</span>
                        {typeof(dealership.bbb_url) === 'string' && (
                            <img
                            alt={`${dealership.name} BBB Business Review`}
                            className={'text-button'} 
                            onClick={window.open.bind(this, `${dealership.bbb_url}/#sealclick`)}
                            src={'images/bbb-badge.png'} 
                            style={{
                                border: 0,
                                height: 50,
                                marginTop: 12,
                                objectFit: 'contain',
                                width: 'auto'
                            }} />
                        )}
                    </div>
                    {typeof(dealership.disclaimer_text) === 'string' && (
                        <div style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'center',
                            width: '100%'
                        }}>
                            <span style={{
                                color: 'white',
                                display: 'block',
                                fontSize: 12,
                                fontFamily: Appearance.fonts.getFamilyForWeight(500),
                                maxWidth: 1000,
                                width: '75%'
                            }}>{dealership.disclaimer_text}</span>
                        </div>
                    )}
                    <div style={{
                        alignItems: 'flex-end',
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'right'
                    }}>
                        <span 
                        className={'text-button'}
                        onClick={window.open.bind(this, 'https://homesafealerts.com/dealers')}
                        style={{
                            color: 'white',
                            fontSize: 16,
                            fontFamily: Appearance.fonts.getFamilyForWeight(600),
                            marginBottom: 8,
                            textDecoration: 'underline',
                            whiteSpace: 'nowrap'
                        }}>{'Dealer Login'}</span>
                        <span style={{
                            color: 'white',
                            fontSize: 16,
                            fontFamily: Appearance.fonts.getFamilyForWeight(600),
                            marginBottom: 8,
                            whiteSpace: 'nowrap'
                        }}>{`Website Designed by `}
                            <span 
                            className={'text-button'}
                            onClick={window.open.bind(this, 'https://duralabs.io')}
                            style={{
                                textDecoration: 'underline'
                            }}>{`Dura Labs LLC`}</span>
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    return getContent();
}

export const NavigationSectionHeader = ({ dealership, identifier, title, utils }) => {

    const ref = useRef(null);

    const getContent = () => {
        return dealership && (
            <div 
            className={'diamonds'}
            id={`navigation_component_${identifier}`}
            ref={ref}
            style={{
                alignItems: 'center',
                backgroundColor: dealership.colors.primary || Appearance.colors.dim,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                lineHeight: 1,
                padding: Utils.isMobile() === true ? 36 : '5vw',
                position: 'relative',
                textAlign: Utils.isMobile() === true ? 'center' : 'left',
                width: '100%'
            }}>
                <span style={{
                    color: 'white',
                    fontSize: 48,
                    fontFamily: Appearance.fonts.getFamilyForWeight(800),
                    paddingTop: Utils.isMobile() === true ? 12 : 0
                }}>{title.toUpperCase()}</span>
            </div>
        )
    }

    useEffect(() => {
        if(ref.current) {
            utils.navigation.setWaypointObserver(ref.current, identifier);
        }
    }, [ref.current]);
    
    return getContent();
}