import React, { useRef, useState, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import moment from 'moment';

import Appearance from 'styles/Appearance';
import Utils from 'files/Utils.js';

const DatePicker = ({ blockStyle, date, dateTime, filterDate, highlightDates, onClose, onDateChange, onRemoveDate, removeable, time, utils }) => {

    const headers = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];

    const [animations, setAnimations] = useSpring(() => ({
        config: { mass: 1, tension: 180, friction: 16 },
        opacity: 1,
        transform: 'scale(0.5)'
    }));
    const [days, setDays] = useState([]);
    const [offset, setOffset] = useState(0);
    const [selectedDate, setSelectedDate] = useState(date ? moment(date) : moment());
    const [selectedTime, setSelectedTime] = useState(null);
    const [target] = useState(date ? moment(date) : moment());
    const [times, setTimes] = useState([]);
    const [timeOfDay, setTimeOfDay] = useState(null);
    const [visible, setVisible] = useState(false);
    const [size, setSize] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    const onAnimateElementsIntoView = () => {
        setVisible(true);
        setAnimations({
            opacity: 1,
            transform: 'scale(1)'
        });
    }

    const onConfirmDate = key => {

        // determine if date was requested for removal and prevent moving forward if applicable
        if(key === 'remove') {
            onHideAlert();
            if(typeof(onRemoveDate) === 'function') {
                onRemoveDate(null);
            }
            return;
        }

        // prepare date target value and determine if all variables have been selected by the user
        let dateTarget = moment(selectedDate);
        if(time || dateTime) {

            // prevent moving forward if no time was selected
            if(!selectedTime) {
                utils.alert.show({
                    title: 'Just a Second',
                    message: 'Please select a time before confirming your date'
                });
                return;
            }

            // prepare new date target with time component if applicable
            dateTarget = time ? moment(selectedTime) : moment(`${moment(selectedDate).format('YYYY-MM-DD')} ${selectedTime.format('HH:mm:ss')}`)
        }

        // hide alert and notify subscribers of date change
        onHideAlert();
        if(typeof(onDateChange) === 'function') {
            onDateChange(dateTarget);
        }
    }

    const onDateClick = entry => {
        setSelectedDate(entry.date.format('YYYY-MM-DD'));
    }

    const onHideAlert = callback => {
        
        // animate components out of view
        setAnimations({
            opacity: 0,
            transform: 'scale(0.5)'
        });

        // allow animation to complete before closing alert and notifying subscribers
        setTimeout(() => {
            setVisible(false);
            if(typeof(onClose) === 'function') {
                onClose();
            }
            if(typeof(callback) === 'function') {
                callback();
            }
        }, 250);
    }

    const onSelectTime = unix => {
        if(!isTimeAvailable(moment.unix(unix))) {
            return;
        }
        setSelectedTime(moment.unix(unix));
    }

    const onSetDatesAndTimes = () => {

        // prevent moving forward if no target was provided
        if(!target) {
            return;
        }

        // prepare start of month and total number of days required to fill up the amount of weeks needed for the selected month
        // 42 days accounts for 6 full rows of 7 day weeks if first of the month is thursday or later
        let startOfMonth = parseInt(moment(target).startOf('month').add(offset, 'months').format('e'));
        let totalDays = 42;

        // loop through days and prepare date objects
        let days = [...new Array(totalDays)].map((_, day) => {
            return day < startOfMonth ? moment(target).startOf('month').add(offset, 'months').subtract(startOfMonth - day, 'days') : moment(target).startOf('month').add(offset, 'months').add(day - startOfMonth, 'days');
        });

        // loop through date objects and create weeks
        let weeks = days.reduce((array, date, index) => {
            let i = Math.floor(index / headers.length);
            if(!array[i]) {
                array[i] = [];
            }
            array[i].push({
                date: date,
                isToday: date.isSame(moment(), 'day')
            });
            return array;
        }, []);
        setDays(weeks);

        // prevent moving forward if time selectors are not required
        if(!time && !dateTime) {
            return;
        }

        // loop through dates and prepare time objects
        let index = 0;
        let times = [];
        while (moment(target).add(offset, 'months').startOf('day').add(index * 15, 'minutes') <= moment(target).add(offset, 'months').endOf('day')) {
            let time = moment(target).add(offset, 'months').startOf('day').add(index * 15, 'minutes');
            times.push(time);
            index++;
        }

        // loop through times and attempt to find closest matching time to the provided time
        let needle = moment(target).unix();
        let closestTime = times.reduce((a, b, index) => {

            // filter out not matching times if applicable
            if(typeof(filterDate) === 'function' && !filterDate(b)) {
                return a;
            }
            // compare unix timestamps
            return Math.abs(b.unix() - needle) < Math.abs(a.unix() - needle) ? b : a;
        }, times[0]);

        // update state values 
        let selectedTime = times.find(time => time === closestTime);
        setTimes(times);
        setSelectedTime(selectedTime);
        setTimeOfDay(selectedTime ? moment(selectedTime).format('a').toLowerCase() : moment().format('a').toLowerCase());
    }

    const onWindowSizeChange = () => {
        setSize({
            height: window.innerHeight,
            width: window.innerWidth
        });
    }

    const isDateAvailable = newDate => {
        return newDate.isSame(moment(target).add(offset, 'months'), 'month');
    }

    const isTimeAvailable = date => {
        if(typeof(filterDate) === 'function') {
            return filterDate(date);
        }
        return true;
    }

    const shouldHighlightDate = date => {

        // highlight dates are not needed unless explicitly requested 
        if(highlightDates !== true) {
            return false;
        }

        // determine if date falls within range of predetermined highlight dates
        if(!blockStyle) {
            return highlightDates.find(d => moment(d).unix() === moment(date).unix())
        }

        // determine if date falls within range of predetermined highlight dates and how the resulting block should be visually formatted
        return moment(date).unix() >= moment(selectedDate).startOf(blockStyle).unix() && moment(date).unix() <= moment(selectedDate).endOf(blockStyle).unix()
    }

    const getButtons = () => {

        // declare buttons targets
        let targets = [{
            key: 'done',
            title: removeable ? 'Use Date' : 'Done',
            style: 'default'
        },{
            key: 'remove',
            title: 'Remove Date',
            style: 'destructive',
            visible: removeable ? true : false
        }];

        return targets.filter(item => item.visible !== false).map(item => (
            <div
            key={item.key}
            className={`alert-item ${window.theme}`}
            onClick={onConfirmDate.bind(this, item.key)}
            style={{
                height: 50,
                width: '100%'
            }}>
                <div style={{
                    backgroundColor: Appearance.colors.divider(),
                    height: 1,
                    width: '100%'
                }}/>
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    height: 50,
                    justifyContent: 'center'
                }}>
                    <span style={{
                        color: (item.style == 'cancel' || item.style == 'destructive') ? Appearance.colors.text() : Appearance.colors.primary(),
                        fontSize: 13,
                        fontWeight: 400,
                        textAlign:'center'
                    }}>{item.title}</span>
                </div>
            </div>
        ));
    }

    const getHeadersDays = () => {
        return (
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                height: 40,
                justifyContent: 'space-around',
                marginBottom: 8,
                textAlign: 'center',
                width: '100%'
            }}>
                {headers.map((day, index) => (
                    <span 
                    key={index}
                    style={{
                        ...Appearance.textStyles.supporting(),
                        color: Appearance.colors.lightGrey,
                        flexGrow: 1,
                        fontWeight: '600',
                        textAlign: 'center',
                        width: '100%'
                    }}>{day.toUpperCase()}</span>
                ))}
            </div>
        )
    }

    const getMonth = () => {
        return moment(target).startOf('month').add(offset, 'months').format('MMMM YYYY')
    }

    const getTimes = () => {
        return times.filter(time => {
            return time.format('a').toLowerCase() === timeOfDay;
        }).map((time, index) => (
            <span
            key={index}
            className={'text-button'}
            style={{
                ...Appearance.textStyles.title(),
                fontWeight: '600',
                textAlign: 'center',
                fontSize: 14,
                paddingLeft: 6,
                paddingRight: 6,
                ...selectedTime && {
                    color: isTimeAvailable(time) && time.unix() === selectedTime.unix() ? Appearance.colors.primary() : Appearance.colors.text(),
                    opacity: isTimeAvailable(time) ? 1 : 0.25
                }
            }}>
                {time.format('h:mm')}
            </span>
        ))
    }

    const getDateComponents  = () => {

        // prevent moving forward if requested picker style is for times
        if(time === true) {
            return null;
        }

        return (
            <div style={{
                border: `1px solid ${Appearance.colors.divider()}`,
                borderRadius: 10,
                padding: 15
            }}>
                <div style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingLeft: 8,
                    paddingRight: 8,
                    width: '100%',
                }}>
                    <img 
                    className={'text-button'}
                    onClick={() => setOffset(offset => offset - 12)}
                    src={'images/back-double-arrow-light-grey-small.png'}
                    style={{
                        height: 15,
                        marginRight: 4,
                        minHeight: 15,
                        minWidth: 15,
                        objectFit: 'contain',
                        width: 15
                    }} />
                    <img 
                    className={'text-button'}
                    onClick={() => setOffset(offset => offset - 1)}
                    src={'images/back-arrow-light-grey-small.png'}
                    style={{
                        height: 15,
                        marginRight: 8,
                        minHeight: 15,
                        minWidth: 15,
                        objectFit: 'contain',
                        width: 15,
                    }} />
                    <span style={{
                        ...Appearance.textStyles.title(),
                        fontSize: 16,
                        fontWeight: '600',
                        textAlign: 'center',
                        width: '100%'
                    }}>{getMonth()}</span>
                    <img 
                    className={'text-button'}
                    onClick={() => setOffset(offset => offset + 1)}
                    src={'images/next-arrow-light-grey-small.png'}
                    style={{
                        height: 15,
                        marginLeft: 8,
                        minHeight: 15,
                        minWidth: 15,
                        objectFit: 'contain',
                        width: 15
                    }} />
                    <img 
                    className={'text-button'}
                    onClick={() => setOffset(offset => offset + 12)}
                    src={'images/next-double-arrow-light-grey-small.png'}
                    style={{
                        height: 15,
                        marginLeft: 4,
                        minHeight: 15,
                        minWidth: 15,
                        objectFit: 'contain',
                        width: 15,
                    }} />
                </div>
                {getHeadersDays()}
                {getWeeks()}
            </div>
        )
    }

    const getDateStyles = (i, length, date) => {
        let highlightDate = shouldHighlightDate(date);
        if(highlightDate && blockStyle) {
            return {
                backgroundColor: Appearance.colors.primary(),
                borderBottomLeftRadius: i === 0 ? 10 : 0,
                borderBottomRightRadius: i === length - 1 ? 10 : 0,
                borderTopLeftRadius: i === 0 ? 10 : 0,
                borderTopRightRadius: i === length - 1 ? 10 : 0,
            };
        }
        return null;
    }

    const getDateTextStyles = entry => {

        // prepare text stlyes for highlight dates and dates within blocks if applicable
        let highlightDate = shouldHighlightDate(entry.date);
        if(highlightDate && blockStyle) {
            return {
                ...Appearance.textStyles.title(),
                fontWeight: '500',
                textAlign: 'center',
                width: '100%',
                fontSize: 14,
                color: 'white',
                opacity: 1,
            }
        }

        // prepare text styles for dates that are available to select and dates that are already selected
        let available = isDateAvailable(entry.date);
        let isSelected = selectedDate && entry.date.isSame(moment(selectedDate), 'day');
        return {
            ...Appearance.textStyles.title(),
            color: isSelected ? 'white' : ((highlightDate || entry.isToday) ? Appearance.colors.primary() : Appearance.textStyles.title().color),
            fontSize: 14,
            fontWeight: highlightDate ? '600' : '500',
            opacity: available || isSelected ? 1 : 0.25,
            textAlign: 'center',
            width: '100%'
        }
    }

    const getWeeks = () => {
        return days.map((week, index) => (
            <div
            key={index}
            style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                marginBottom: index !== days.length - 1 ? 4 : 0,
                width: '100%'
            }}>
                {week.map((entry, index) => {
                    let isDateSelected = entry.date.isSame(moment(selectedDate), 'day');
                    let isSameMonth = entry.date.isSame(target, 'month');
                    return (
                        <div
                        key={index}
                        className={(Utils.isMobile() || isSameMonth) ? 'text-button' : ''}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            justifyContent: 'center',
                            height: '100%',
                            paddingLeft: 5,
                            paddingRight: 5,
                            width: '100%',
                            ...getDateStyles(index, week.length, entry.date)
                        }}>
                            <div
                            onClick={onDateClick.bind(this, entry)}
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                                flexGrow: 1,
                                flexDirection: 'column',
                                height: '100%',
                                width: '100%'
                            }}>
                                <div
                                className={'cursor-pointer'}
                                style={{
                                    alignItems: 'center',
                                    backgroundColor: selectedDate && isDateSelected ? Appearance.colors.primary() : null,
                                    borderRadius: 8,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 30,
                                    padding: 4,
                                    width: 30
                                }}>
                                    <span style={getDateTextStyles(entry)}>{entry.date.format('D')}</span>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        ));
    }

    useEffect(() => {
        onSetDatesAndTimes();
    }, [target, offset]);

    useEffect(() => {
        onAnimateElementsIntoView();
        window.addEventListener('resize', onWindowSizeChange);
        return () => {
            window.removeEventListener('resize', onWindowSizeChange);
        }
    }, []);

    return visible && (
        <div style={{
            alignItems: 'center',
            backgroundColor: Appearance.colors.transparent,
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            left: 0,
            position: 'fixed',
            top: 0,
            width: '100%',
            zIndex: 9950
        }}>
            <animated.div style={{
                backgroundColor: Appearance.colors.dim,
                bottom: 0,
                left: 0,
                opacity: animations.opacity,
                position: 'absolute',
                right: 0,
                top: 0
            }} />
            <animated.div style={{
                alignItems: 'center',
                backgroundColor: Appearance.colors.alert(),
                borderRadius: 10,
                maxHeight: size.height - 30,
                opacity: animations.opacity,
                overflow:'hidden',
                textAlign: 'center',
                transform: animations.transform,
                width: size.width > 400 ? 400 : (size.width - 30),
                zIndex: 9999
            }}>
                <div style={{
                    display: 'block',
                    padding: 15
                }}>
                    {getDateComponents()}
                </div>
                {getButtons()}
            </animated.div>
        </div>
    )
}

export default DatePicker;
