import API from 'files/api.js';

const buildRequest = (url, props) => {
    let newURL = `${url}?type=${props.type}`;
    for(var i in props) {
        if(i == 'type') {
            continue;
        }
        newURL += `&${i}=${typeof(props[i]) === 'object' ? JSON.stringify(props[i]) : encodeURIComponent(props[i])}`;
    }
    return newURL;
}

const del = async (utils, url, props, server) => {
    return new Promise(async (resolve, reject) => {
        try {

            let response = await fetch(server ? `${server}${url}` : `/api/v${API.version}${url}`, {
                method: 'DELETE',
                withCredentials: true,
                headers: utils.api.headers(),
                body: JSON.stringify(props)
            });

            // determine if response code represents a 5xx level error
            if([502, 503, 504].includes(response.status)) {
                throw new Error('Please try again in a few minutes');
            }

            // prevent moving forward if an error was thrown
            if(response.status !== 200) {
                let text = await response.text();
                throw new Error(text || 'An unknown error occurred');
            }

            let json = await response.json();
            resolve(json);

        } catch(e) {
            reject(e);
        }
    })
}

const get = async (utils, url, props, server) => {
    return new Promise(async (resolve, reject) => {
        try {

            let response = await fetch(buildRequest(server ? `${server}${url}` : `/api/v${API.version}${url}`, props), {
                method: 'GET',
                withCredentials: true,
                headers: utils.api.headers()
            });

            // determine if response code represents a 5xx level error
            if([502, 503, 504].includes(response.status)) {
                throw new Error('Please try again in a few minutes');
            }

            // prevent moving forward if an error was thrown
            if(response.status !== 200) {
                let text = await response.text();
                throw new Error(text || 'An unknown error occurred');
            }

            let json = await response.json();
            resolve(json);

        } catch(e) {
            reject(e);
        }
    })
}

const post = async (utils, url, props, server) => {
    return new Promise(async (resolve, reject) => {
        try {

            let response = await fetch(server ? `${server}${url}` : `/api/v${API.version}${url}`, {
                method: 'POST',
                withCredentials: true,
                headers: utils.api.headers(),
                body: JSON.stringify(props)
            });

            // determine if response code represents a 5xx level error
            if([502, 503, 504].includes(response.status)) {
                throw new Error('Please try again in a few minutes');
            }

            // prevent moving forward if an error was thrown
            if(response.status !== 200) {
                let text = await response.text();
                throw new Error(text || 'An unknown error occurred');
            }

            let json = await response.json();
            resolve(json);

        } catch(e) {
            reject(e);
        }
    })
}

const put = async (utils, url, props, server) => {
    return new Promise(async (resolve, reject) => {
        try {

            let response = await fetch(server ? `${server}${url}` : `/api/v${API.version}${url}`, {
                method: 'PUT',
                withCredentials: true,
                headers: utils.api.headers(),
                body: JSON.stringify(props)
            });

            // determine if response code represents a 5xx level error
            if([502, 503, 504].includes(response.status)) {
                throw new Error('Please try again in a few minutes');
            }

            // prevent moving forward if an error was thrown
            if(response.status !== 200) {
                let text = await response.text();
                throw new Error(text || 'An unknown error occurred');
            }

            let json = await response.json();
            resolve(json);

        } catch(e) {
            console.error(e.message);
            reject(e);
        }
    })
}

const Request = {
    aft: {
        get: (...args) => get(...args, API.aft.api_url),
        post: (...args) => post(...args, API.aft.api_url)
    },
    delete: del,
    get: get,
    post: post,
    put: put
}
export default Request;
