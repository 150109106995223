class EventManager {

    subscribers = {};
    constructor() {
        return this;
    }

    on = (id, handle, callback) => {
        window.addEventListener(handle, callback);
        this.subscribers[id] = {
            ...this.subscribers[id],
            [handle]: callback
        };
    }

    emit = (handle, data) => {
        let evt = new CustomEvent(handle, { detail: data });
        window.dispatchEvent(evt);
    }

    off = (id, handle) => {
        if(this.subscribers[id]) {
            window.removeEventListener(handle, this.subscribers[id][handle]);
        }
        delete this.subscribers[id].handle;
    }
}
export default {
    new: () => new EventManager()
};
